import React from 'react';
import PropTypes from 'prop-types';
import ArgsDisplay from './objectHistoryTable/ArgsDisplay'
import Table from '../../general/Table';
import {objectHistoryMethodLabel} from '../../../lib/localizedHistoryLabels';
import {formatDateTime} from '../../../lib/date';
import {getDefaultTablePageSize} from '../../../lib/project';
import {ObjectHistory, ObjectHistoryMethod} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ObjectHistoryTable extends React.PureComponent {
    static propTypes = {
        objectHistory: ObjectHistory.isRequired,
        setLimit: PropTypes.func.isRequired,
        limit: PropTypes.number.isRequired,
        Actions: PropTypes.func,
    };

    render() {
        const {objectHistory, setLimit, limit, Actions} = this.props;
        let colDefs = [
            {
                class: 'mx-auto object-history-col-exp',
            },
            {
                headerCell:  <Trans>Akcia</Trans>,
                class: 'object-history-col-method',
            },
            {
                headerCell: <Trans>Uživateľ</Trans>,
                class: 'object-history-col-user',
            },
            {
                headerCell: <Trans>Vykonané</Trans>,
                class: 'object-history-col-access-time',
            },
            {
                headerCell: <Trans>IP adresa</Trans>,
                class: 'mx-auto object-history-col-ip',
            }
        ];
        if (Actions) {
            colDefs= colDefs.concat(
                {
                    class: 'object-history-col-actions',
                })
        }
        return <Table
            className="object-history-table"
            BodyRow={ObjectHistoryTableRow}
            data={objectHistory}
            limit={limit}
            pageSize={getDefaultTablePageSize()}
            changeLimit={setLimit}
            noTBody={true}
            colDefs={colDefs}
            Actions={Actions}
        />;
    }

}

export default ObjectHistoryTable;

class ObjectHistoryTableRow extends React.PureComponent {
    static propTypes = {
        data: ObjectHistoryMethod.isRequired,
        Actions: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            infoRowVisible: false,
        }
    }

    toggleInfoRowVisibility = () => {
        const {infoRowVisible} = this.state;
        this.setState({
            infoRowVisible: !infoRowVisible
        })
    };

    render() {
        const {data, Actions} = this.props;
        const {infoRowVisible} = this.state;
        return <tbody>
            <MainRow
                infoRowVisible={infoRowVisible}
                toggleInfoRowVisibility={this.toggleInfoRowVisibility}
                data={data}
                Actions={Actions}
            />
            {
                infoRowVisible ? <InfoRow
                    data={data}
                    Actions={Actions}
                /> : null
            }
        </tbody>;
    }
}

function MainRow({data, infoRowVisible, toggleInfoRowVisibility, Actions}) {
    return <tr style={{height: '2.5rem'}}>
        <td className="mx-auto" rowSpan={infoRowVisible ? '2' : '1'}>
            <Button
                size="small"
                onClick={toggleInfoRowVisibility}
                icon={infoRowVisible ? 'down' : 'right'}
                disabled={!(data && data.args && data.args.length > 0)}
            />
        </td>
        <td>
            {objectHistoryMethodLabel(data.method)}
        </td>
        <td>
            {data.who}
        </td>
        <td>
            {formatDateTime(data.access_time)}
        </td>
        <td>
            {data.ip_address}
        </td>
        {Actions ? <td className="text-center mx-auto">
            <Actions data={data} />
        </td> : null}
    </tr>
}

function InfoRow({data, Actions}) {
    return <tr className="shop-table-row-odd">
        <td colSpan={Actions? '5' : '4'}>
            <ArgsDisplay args={data.args} />
        </td>
    </tr>
}
