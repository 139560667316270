import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '../../general/DateRangePicker';
import MethodsSelect from '../../project/MethodsSelect';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import {Trans, t} from '@lingui/macro';
import { Checkbox } from 'antd';
import { getInputOnChangeEventChecked } from '../../../lib/project';

/**
 * @fero
 */

class ProductOrdersParams extends React.PureComponent {
    static propTypes = {
        attributes: PropTypes.object.isRequired,
        setAttributes: PropTypes.func.isRequired,
    };

    render() {
        const {attributes, setAttributes} = this.props;
        return <ColumnFilterLayout
            collapsible={false}
            filters={[
                [
                    {
                        label: <Trans>Akcia: </Trans>,
                        selector:  <MethodsSelect
                            className="object-history-filter"
                            value={attributes.method}
                            onChange={(value) => {setAttributes({method: value})}}
                            allowClear={true}
                        />
                    }
                ],
                [
                    {
                        label: <Trans>Vykonané: </Trans>,
                        selector:  <DateRangePicker
                            value={attributes.accessTime}
                            onChange={(value) => {setAttributes({accessTime: value})}}
                        />
                    }
                ],
                [
                    {
                        selector: <Checkbox
                            checked={attributes.filterCustomer}
                            onChange={(ev) => {
                                const value = getInputOnChangeEventChecked(ev);
                                setAttributes({filterCustomer: value});
                            }}
                        >
                            <Trans>Iba záznamy aktuálnej organizácie</Trans>
                        </Checkbox>
                    }
                ]
            ]}
        />;
    }

}

export default ProductOrdersParams;