import React from 'react';
import PropTypes from 'prop-types';
import deepEqual  from 'deep-equal';
import Loading from '../dataProvider/Loading';
import locationHOC from '../locationProvider/locationHOC';
import withDataHOC from '../dataProvider/withDataHOC';
import ObjectHistoryParams from './objectHistory/ObjectHistoryParams';
import ObjectHistoryTable from './objectHistory/ObjectHistoryTable';
import {nullToUndefined} from '../../lib/object';
import {getDefaultTablePageSize} from '../../lib/project';
import {GLOBAL_DATA} from '../../constants/globalData';
import {createFetchHistoryRecords} from '../../backend/apiCalls';
import { getQueryParamNumber } from '../../lib/url';
import { QUERY_PARAMS } from '../../constants/navigation';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import { Decimal } from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ObjectHistory extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        objectType: PropTypes.string.isRequired,
        objectId: Decimal.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            objectHistory: [],
            attributes: {
                accessTime: null,
                method: null,
                limit: getDefaultTablePageSize(),
                filterCustomer: false,
            },
        }
    }

    setAttributes = (newAttributes) => {
        const {attributes} = this.state;
        this.setState({
            attributes: {
                ...attributes,
                ...newAttributes,
            },
        })
    };

    getFetchAttributes = (props, state) => {
        const {objectId, objectType, location} = props;
        const {attributes} = state;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        let fetchAttributes = {
            id_obj: objectId,
            class: objectType,
            access_time: attributes.accessTime,
            method: attributes.method,
            limit: attributes.limit,
            id_customer: attributes.filterCustomer ? customerId : undefined,
        };
        nullToUndefined(fetchAttributes);
        return fetchAttributes;
    };

    fetchObjectHistory = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchHistoryRecords(),
            this.getFetchAttributes(this.props, this.state),
            (objectHistory) => {
                this.setState({
                    objectHistory: objectHistory,
                })
            }
        )
    };

    componentDidMount() {
        this.fetchObjectHistory();
    }

    componentDidUpdate(prevProps, prevState) {
        const attributes = this.getFetchAttributes(this.props, this.state);
        const prevAttributes = this.getFetchAttributes(prevProps, prevState);
        if (!deepEqual(attributes, prevAttributes)) {
            this.fetchObjectHistory();
        }
    }

    render() {
        const {} = this.props;
        const {objectHistory, attributes} = this.state;
        return <div className="large-modal-content">
            <Loading>
                <TableWithFiltersLayout
                    params={
                        <ObjectHistoryParams
                            attributes={attributes}
                            setAttributes={this.setAttributes}
                        />
                    }
                    table={
                        <ObjectHistoryTable
                            setLimit={(value) => {
                                this.setAttributes({limit: value})
                            }}
                            limit={attributes.limit}
                            objectHistory={objectHistory}
                        />
                    }
                />
            </Loading>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(locationHOC(ObjectHistory));