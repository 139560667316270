import React from 'react';
import {objectHistoryArgsLabel} from '../../../../lib/localizedHistoryLabels'
import {t} from '@lingui/macro';

/**
 * @fero
 */

export default function ArgsDisplay({args = [] ,depth = -1}) {
    depth++;
    if (!Array.isArray(args)) {
        return null
    }
    return args.map(arg => {
        return <div style={{marginLeft: `${depth}rem`}} key={`arg${arg.key}`} className="my-1">
            <Label labelKey={arg.key}/>
            {arg.args ?
                <ArgsDisplay args={arg.args} depth={depth}/> :
                arg.label ?
                    `${arg.label} (${arg.val})` :
                    JSON.stringify(arg.val)}
        </div>
    });

}

function Label({labelKey}) {
    if(Number.isNaN(Number(labelKey)))
        return <b className="mr-1">{`${objectHistoryArgsLabel(labelKey)}:`}</b>
    else
        return <span className="mr-1">{t`${labelKey}. riadok`}</span>;
}