import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../general/Tooltip';
import DisplayModal from '../general/DisplayModal';
import ObjectHistory from './ObjectHistory';
import {ButtonSize, Decimal} from '../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class HistoryModal extends React.PureComponent {
    static propTypes = {
        objectId: Decimal.isRequired,
        objectType: PropTypes.string.isRequired,
        objectName: PropTypes.string,
        buttonLabel: PropTypes.node,
        size: ButtonSize,
        useTooltip: PropTypes.bool,
    };

    static defaultProps = {
        buttonLabel: <Trans>História</Trans>,
        useTooltip: true,
    };

    render() {
        const {objectId, objectType, objectName, buttonLabel, size, useTooltip} = this.props;
        return <DisplayModal
            openNode={
                <Tooltip
                    title={useTooltip ? <span><Trans>História</Trans>{objectName ? ` ${objectName}` : ''}</span> : null}
                >
                    <Button
                        size={size}
                    >
                        {buttonLabel}
                    </Button>
                </Tooltip>
            }
            title={<span><Trans>História</Trans>{objectName ? ` ${objectName}` : ''}</span>}
            modalClassName="large-modal"
        >
            <ObjectHistory
                objectType={objectType}
                objectId={objectId}
            />
        </DisplayModal>
    }

}

export default HistoryModal;