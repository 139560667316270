import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import {objectHistoryMethodLabel} from '../../lib/localizedHistoryLabels';
import {METHODS} from '../../constants/endpoints'
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class MethodsSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onChange: PropTypes.func,
        className: PropTypes.string,
        allowClear: PropTypes.bool,
        focus: PropTypes.bool,
    };

    render() {
        return <Select
            {...this.props}
            options={Object.values(METHODS).map(method => {
               return {
                   value: method,
                   label: objectHistoryMethodLabel(method)
               }
            })}
        />;
    }

}

export default MethodsSelect;